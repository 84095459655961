import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background: ${({ theme }) => theme.colors.bg.regular};
    color: ${({ theme }) => theme.colors.text.regular};
    font-family: ${({ theme }) =>
      `"${theme.fonts.regular}", "${theme.fonts.alternative}", ${theme.fonts.fallbackRegular}`};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.link};

    &:hover {
      color: ${({ theme }) => theme.colors.text.linkHovered};
    }

    &:active {
      color: ${({ theme }) => theme.colors.text.linkActive};
    }
  }
  
  ul {
    list-style-type: none;
  }

  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  h1, h2 {
    color: ${({ theme }) => theme.colors.text.bigHeading};
    font-weight: 900;
  }

  h1 {
    font-family: ${({ theme }) =>
      `"${theme.fonts.mainHeader}", "${theme.fonts.bigHeader}", ${theme.fonts.fallbackRegular}`};
    font-size: 4rem;
    font-style: italic;
  }

  h2 {
    font-family: ${({ theme }) =>
      `"${theme.fonts.bigHeader}", "${theme.fonts.alternative}", ${theme.fonts.fallbackMono}`};
    font-size: 2rem;
    margin: 0;
  }

  h3, h4 {
    color: ${({ theme }) => theme.colors.text.midHeading};
    font-family: ${({ theme }) =>
      `"${theme.fonts.smallHeader}", "${theme.fonts.alternative}", ${theme.fonts.fallbackRegular}`};
  }

  h5, h6 {
    color: ${({ theme }) => theme.colors.text.smallHeading};
    font-family: ${({ theme }) =>
      `"${theme.fonts.smallHeader}", "${theme.fonts.alternative}", ${theme.fonts.fallbackRegular}`};
  }

  nav,
  .external-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    font-family: ${({ theme }) =>
      `"${theme.fonts.code}", "${theme.fonts.bigHeader}", ${theme.fonts.fallbackMono}`};

    a {
      width: 195px;
      border: 1px solid ${({ theme }) => theme.colors.text.link};
      text-align: center;
      padding: 5px;
      margin: 0 1.5rem;

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.text.linkHovered};
        color: ${({ theme }) => theme.colors.linkHovered};
      }

      &.nav-active-link {
        color: ${({ theme }) => theme.colors.linkActive};
        border-width: 2px;
      }
    }
  }
`;

export default GlobalStyle;
