import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const StyledWrapper = styled.footer`
  margin-top: 1rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC)
    repeat;
  padding: 0 30px;
  font-family: ${({ theme }) =>
    `"${theme.fonts.code}", ${theme.fonts.fallbackMono}`};

  p {
    display: inline-block;
    margin: 2rem 0;
    background-color: ${({ theme }) => theme.colors.bg.regular};
    padding: 2px;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <StyledWrapper>
      <p>
        <Link to="/Contact">
          by {data.site.siteMetadata.author} @ 2019-2021
        </Link>
      </p>
    </StyledWrapper>
  )
}

export default Footer
