import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/globalStyles';
import Header from './Header';
import Footer from './Footer';

import { theme } from '../styles/theme';

const StyledWrapper = styled.div`
  @media (max-width: 780px) {
    .content {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  margin: 0 auto;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex-grow: 1;
  }

  main {
    padding: 30px 0;
  }
`;

const Layout = (props) => {
  return (
    <ThemeProvider theme={ theme }>
      <>
        <GlobalStyle />
        <StyledWrapper>
          <div className="content">
            <Header />
            <main>
              { props.children }
            </main>
          </div>
          <Footer />
        </StyledWrapper>
      </>
    </ThemeProvider>
  );
};

export default Layout;
