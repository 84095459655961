export const colors = {
  // textLight: '#fff6da',
  // backgroundDark: '#042f4b',
  // textBigHeader: '#fbc99d',
  // textSmallHeader: '#ed1250',
  // linkLight: '#fbc99d',
  // linkHoveredLight: '#b29f8f',
  // linkActiveLight: '#fff8ea',

  text: {
    regular: '#000000',
    bigHeading: '#000000',
    midHeading: '#000000',
    smallHeading: '#000000',
    link: '#0E2F56',
    linkActive: '#042f4b',
    linkHovered: '#118DF0',
  },
  bg: {
    regular: '#F4FAFF',
  },
  // text: {
  //   regular: '#ECECDA',
  //   bigHeading: '#FF304F',
  //   midHeading: '#FF304F',
  //   smallHeading: '#FF304F',
  //   link: '#ECECDA',
  //   linkActive: '#ECECDA',
  //   linkHovered: '#118DFO',
  // },
  // bg: {
  //   regular: '#0E2F56',
  // },
  // text: {
  //   regular: '#F0D43A',
  //   bigHeading: '#F23557',
  //   midHeading: '#F23557',
  //   smallHeading: '#F23557',
  //   link: '#22B2DA',
  //   linkActive: '#ECECDA',
  //   linkHovered: '#118DFO',
  // },
  // bg: {
  //   regular: '#3B4A6B',
  // },
  // text: {
  //   regular: '#F4FAFF',
  //   bigHeading: '#F61067',
  //   midHeading: '#F23557',
  //   smallHeading: '#F23557',
  //   link: '#00F0B5',
  //   linkActive: '#ECECDA',
  //   linkHovered: '#EE6055',
  // },
  // bg: {
  //   regular: '#0C1D47',
  // },
  // text: {
  //   regular: '#000000',
  //   bigHeading: '#393C3A',
  //   midHeading: '#393C3A',
  //   smallHeading: '#393C3A',
  //   link: '#00F0B5',
  //   linkActive: '#8B9EB7',
  //   linkHovered: '#C9C5CB',
  // },
  // bg: {
  //   regular: '#F4FAFF',
  // },
  // text: {
  //   regular: '#C4EDDE',
  //   bigHeading: '#F73859',
  //   midHeading: '#F73859',
  //   smallHeading: '#F73859',
  //   link: '#7AC7C4',
  //   linkActive: '#ECECDA',
  //   linkHovered: '#C4EDDE',
  // },
  // bg: {
  //   regular: '#384259',
  // },
};
