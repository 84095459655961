import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
      <script>
        var clicky_site_ids = clicky_site_ids || [];
        clicky_site_ids.push(101306682);
      </script>
      <script async src="//static.getclicky.com/js"></script>
    </Helmet>
  );
};

export default Head;
