import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const StyledWrapper = styled.header`
  h1 {
    border: 15px black solid;
    padding: 15px;
    margin: 35px 0;
    text-align: center;
    filter: drop-shadow(12px 12px 0px rgba(0, 0, 0, 0.25));
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <Link className="link" to="/">
        <h1>{data.site.siteMetadata.title}</h1>
      </Link>
      <nav>
        <Link activeClassName="nav-active-link" to="/">
          Blog
        </Link>
        <Link activeClassName="nav-active-link" to="/Contact">
          Kontakt
        </Link>
      </nav>
    </StyledWrapper>
  );
};

export default Header;
