import '../styles/fontsStyles.css';

export const fonts = {
  mainHeader: 'Montserrat',
  bigHeader: 'IBM Plex Mono',
  smallHeader: 'Roboto',
  regular: 'IBM Plex Sans',
  alternative: 'Open Sans',
  fallbackRegular: 'sans-serif',
  code: 'IBM Plex Mono',
  fallbackMono: 'monospace',
};
